/* @import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:600"); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

html, body, #root {
  height: auto;
  min-height: 100%;
  min-height: 100dvh;
}

#root {
  overflow: auto;
  display: flex;
  justify-content: center;
}

body,
td {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #ffffff;
}

@media (min-width: 769px) {
  body {
    background-color: #f6f6f6;
  }
}

.dropdown {
  position: relative;
}

.dropdown-menu,
.dropdown-menu:focus-within {
  position: absolute;
  left: 0px;
  top: 65%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transform: translateY(-10px);
  width: 100%;
  height: 100%;
}

.dropdown > .link:hover + .dropdown-menu,
.dropdown > .link:focus-within + .dropdown-menu,
.dropdown > .link + .dropdown-menu:hover,
.dropdown > .link + .dropdown-menu:focus {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.ease {
  animation: animatedDiv 150ms linear 1;
  position: relative;
}

@keyframes animatedDiv {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.ease-in-navbar {
  animation: easeInNavbar 300ms linear 1;
  position: relative;
  z-index: 20;
}

@keyframes easeInNavbar {
  0% {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

.ease-out-navbar {
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: translateY(-100%);
}

/* Below is for displaying a tooltip above "Click to Copy" areas */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 65px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  right: -35px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Below is for creating a custom checkbox that can be modified, css-wise */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 41px;
  margin-bottom: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: Calc(50% - 70px);
}

/* Below is for creating a custom checkbox that can be modified, css-wise */
.checkbox-container-right-aligned {
  display: block;
  position: relative;
  margin-bottom: 0px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
  width: 100%;
}

/* Hide the browser's default checkbox */
.checkbox-container input, .checkbox-container-right-aligned input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #404040;
}

/* Create a custom checkbox */
.checkmark-right-aligned {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #404040;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark, .checkbox-container-right-aligned input ~ .checkmark-right-aligned {
  background-color: #404040;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark, .checkbox-container-right-aligned input:checked ~ .checkmark-right-aligned {
  background-color: #404040;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after, .checkmark-right-aligned:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after, .checkbox-container-right-aligned input:checked ~ .checkmark-right-aligned:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after, .checkbox-container-right-aligned .checkmark-right-aligned:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* THE FOLLOWING STYLES ARE FOR A DISABLED CHECKBOX THAT CANNOT BE TOGGLED */
/* Below is for creating a custom checkbox that can be modified, css-wise */
.checkbox-container-disabled {
  display: block;
  position: relative;
  padding-left: 41px;
  margin-bottom: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: Calc(50% - 70px);
}

/* Below is for creating a custom checkbox that can be modified, css-wise */
.checkbox-container-right-aligned-disabled {
  display: block;
  position: relative;
  margin-bottom: 0px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
  width: 100%;
}

/* Hide the browser's default checkbox */
.checkbox-container-disabled input, .checkbox-container-right-aligned-disabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-disabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ddd;
}

/* Create a custom checkbox */
.checkmark-right-aligned-disabled {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #ddd;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container-disabled:hover input ~ .checkmark-disabled, .checkbox-container-right-aligned-disabled input ~ .checkmark-right-aligned-disabled {
  background-color: #ddd;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container-disabled input:checked ~ .checkmark-disabled, .checkbox-container-right-aligned-disabled input:checked ~ .checkmark-right-aligned-disabled {
  background-color: #ddd;
}

/* Create the disabled checkmark/indicator (hidden when not checked) */
.checkmark-disabled:after, .checkmark-right-aligned-disabled:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container-disabled input:checked ~ .checkmark-disabled:after, .checkbox-container-right-aligned-disabled input:checked ~ .checkmark-right-aligned-disabled:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container-disabled .checkmark-disabled:after, .checkbox-container-right-aligned-disabled .checkmark-right-aligned-disabled:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid #777;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */

 html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  font-weight: 400;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

.fullPageLoadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100vh;
  height: 100dvh;
}

/* radio button styling */
.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 11px;
  content: " ";
  display: block;
  background: #9c1b30;
}